/* 首页样式 */
.joe_index {
	border-radius: var(--radius-wrap);
	padding: 0 15px;
	background: var(--background);
	box-shadow: var(--box-shadow);
	&__banner {
		padding-top: 15px;
		display: flex;
		&-recommend {
			width: 270px;
			margin-left: 15px;
			display: flex;
			flex-direction: column;
			&.noswiper {
				width: 100%;
				flex-direction: row;
				margin-left: 0;
				.item:first-child {
					margin-bottom: 0;
					margin-right: 7.5px;
				}
				.item:last-child {
					margin-left: 7.5px;
				}
			}
			.item {
				position: relative;
				width: 100%;
				height: 160px;
				margin-bottom: 15px;
				border-radius: var(--radius-inner);
				overflow: hidden;
				&:last-child {
					margin-bottom: 0;
				}
				.thumbnail {
					display: block;
					width: 100%;
					height: 100%;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.information {
					display: flex;
					align-items: center;
					position: absolute;
					z-index: 1;
					bottom: 0;
					left: 0;
					right: 0;
					padding: 8px;
					background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
					color: #fff;
					line-height: 20px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					.type {
						background-image: linear-gradient(270deg, #986fee, #8695e6, #68b7dd, #18d7d3);
						background-color: #8695e6;
						text-align: center;
						padding: 0 8px;
						border-radius: 2px;
						height: 20px;
						font-size: 12px;
						color: #fff;
						z-index: 2;
						user-select: none;
						margin-right: 5px;
					}
					.text {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}
		}
	}
	&__hot {
		padding-top: 15px;
		&-list {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			column-gap: 15px;
			.item {
				overflow: hidden;
				.link {
					display: block;
					.inner {
						position: relative;
						&:hover {
							.image {
								opacity: 0.85;
							}
							.title {
								background: var(--classC);
							}
						}
						.image {
							width: 100%;
							height: 120px;
							object-fit: cover;
							transition: opacity 0.35s;
							border-radius: var(--radius-inner) var(--radius-inner) 0 0;
						}
						.title {
							font-size: 13px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							text-align: center;
							padding: 0 8px;
							line-height: 32px;
							color: var(--minor);
							background: var(--classD);
							transition: background 0.35s;
							border-radius: 0 0 var(--radius-inner) var(--radius-inner);
						}
						.views {
							display: flex;
							align-items: center;
							position: absolute;
							z-index: 1;
							top: 5px;
							right: 5px;
							background-image: linear-gradient(to right, #fc712a, #f84c39);
							background-color: #f84c39;
							color: #fff;
							font-size: 12px;
							height: 18px;
							padding: 0 8px;
							border-radius: 2px;
							white-space: nowrap;
						}
					}
				}
			}
		}
	}
	&__ad {
		padding-top: 15px;
		&-link {
			display: block;
			position: relative;
			.image {
				width: 100%;
				height: 200px;
				object-fit: cover;
				border-radius: var(--radius-inner);
				transition: opacity 0.35s;
				&:hover {
					opacity: 0.85;
				}
			}
			.icon {
				position: absolute;
				z-index: 1;
				top: 10px;
				right: 10px;
				font-size: 12px;
				background: rgba(0, 0, 0, 0.25);
				padding: 2px 5px;
				border-radius: 2px;
				color: #ebebeb;
				pointer-events: none;
			}
		}
	}
	&__title {
		display: flex;
		align-items: center;
		border-bottom: 1px solid var(--classC);
		&-title {
			position: relative;
			display: flex;
			align-items: center;
			color: var(--routine);
			font-weight: 500;
			height: 40px;
			line-height: 40px;
			.item {
				cursor: pointer;
				margin-right: 20px;
				user-select: none;
				transition: color 0.35s;
				&.active {
					color: var(--theme);
				}
			}
			.line {
				position: absolute;
				bottom: -1px;
				left: 0;
				height: 2px;
				border-radius: 1px;
				background: var(--theme);
				transition: left 0.35s, width 0.35s;
			}
		}
		&-notice {
			display: flex;
			align-items: center;
			margin-left: auto;
			height: 40px;
			svg {
				min-width: 20px;
				min-height: 20px;
				width: 20px;
				height: 20px;
				margin-right: 5px;
			}
			a {
				color: var(--minor);
				line-height: 20px;
				max-width: 300px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				transition: color 0.35s;
				&:hover {
					color: var(--theme);
				}
			}
		}
	}
}

.swiper-container {
	min-width: 0;
	flex: 1;
	height: 335px;
	--swiper-theme-color: #fff;
	border-radius: var(--radius-inner);
	.item {
		display: block;
		height: 335px;
		border-radius: var(--radius-inner);
		.thumbnail {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: opacity 0.35s;
			&:hover {
				opacity: 0.85;
			}
		}
		.title {
			position: absolute;
			z-index: 1;
			left: 0;
			right: 0;
			bottom: 0;
			text-align: center;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.45));
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: #fff;
			font-size: 15px;
			padding: 10px;
			font-weight: normal;
		}
		.icon {
			position: absolute;
			z-index: 1;
			top: 10px;
			left: 10px;
			width: 18px;
			height: 18px;
			fill: #fff;
			opacity: 0.5;
		}
	}
	&.swiper-container-horizontal {
		.swiper-pagination-bullets {
			bottom: unset;
			left: unset;
			width: auto;
			right: 10px;
			top: 10px;
		}
	}
	&.swiper-container-vertical {
		.swiper-button-next,
		.swiper-button-prev {
			display: none;
		}
	}
	.swiper-button-next,
	.swiper-button-prev {
		background: rgba(0, 0, 0, 0.1);
		transition: background 0.35s, right 0.35s, left 0.35s;
		border-radius: 2px;
		&:hover {
			background: rgba(0, 0, 0, 0.25);
		}
		&::after {
			font-size: 20px;
		}
	}
	.swiper-button-next {
		right: -27px;
	}
	.swiper-button-prev {
		left: -27px;
	}
	&:hover {
		.swiper-button-next {
			right: 10px;
		}
		.swiper-button-prev {
			left: 10px;
		}
	}
}
